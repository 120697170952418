import { NextSeo } from "next-seo";
import HeroSection from "@/components/home/hero-section";
import AboutSection from "@/components/home/about-section";
import SkillsSection from "@/components/home/skills-section";
import ServicesSection from "@/components/home/services-section";
import ContactSection from "@/components/home/contact-section";
import Testimonials from "@/components/home/testimonials-section";
import FreelancerLinks from "@/components/contact/freelancer-links";

export default function Home() {
  return (
    <>
      <NextSeo
        title="Waleed Salama"
        description="Passionate Problem Solver & Artificial Intelligence Software Engineer"
        canonical="https://waleed.dev"
      />
      <HeroSection />
      <AboutSection className="container max-w-prose pb-12 pt-24" />
      <SkillsSection className="container columns-1 gap-4 py-12 md:columns-2 md:gap-8" />
      <ServicesSection className="container py-12" />
      <Testimonials />
      <ContactSection className="container py-12" />
      <div className="container px-4 pb-24">
        <FreelancerLinks />
      </div>
    </>
  );
}
