import Image from "next/image";
import React from "react";
import waleedHeadshot from "@/public/WaleedHeadshot.webp";

const HeroSection = () => {
  const [bgRepeatX, setBgRepeatX] = React.useState(25);
  const [bgRepeatY, setBgRepeatY] = React.useState(5);
  React.useEffect(() => {
    const checkBgRepeat = () => {
      setBgRepeatX(Math.floor(window.innerWidth / 40));
      setBgRepeatY(
        Math.min(Math.max(Math.floor(3000 / window.innerWidth), 3), 5),
      );
    };
    checkBgRepeat();
    window.addEventListener("resize", checkBgRepeat);
    return () => {
      window.removeEventListener("resize", checkBgRepeat);
    };
  }, []);

  return (
    <section className="relative w-full">
      <div className="pointer-events-none relative z-20 flex flex-col items-center justify-center md:container sm:flex-row-reverse">
        <div className="flex flex-col justify-center gap-4 p-4 text-primary-950/90">
          <h1 className="pt-0 text-center text-3xl font-normal text-primary-950/90 high-contrast-theme:font-medium sm:text-left">
            <span className="text-center text-5xl font-thin high-contrast-theme:font-light sm:text-left">
              Waleed Salama
              <br />
            </span>
            <span className="sr-only">{" - "}</span>
            Passionate Problem Solver & Artificial Intelligence Software
            Engineer
          </h1>
        </div>
        <Image
          src={waleedHeadshot}
          alt="Waleed Salama"
          quality={100}
          priority
        />
      </div>
      {/* <Link
        href="/contact"
        className="absolute bottom-8 right-8 z-10 rounded bg-secondary/90 p-2 text-white transition-all duration-100 hover:bg-secondary-400/90 active:bg-secondary/90 active:shadow-[inset_0_2px_4px_0_rgba(0,0,0,0.2)]"
      >
        Contact Me
      </Link> */}
      <div className="absolute bottom-0 left-0 right-0 top-0 overflow-hidden bg-gradient-to-t from-primary-300/90 high-contrast-theme:from-primary-50">
        <div className="pointer-events-none absolute -left-20 bottom-0 right-0 top-14 z-10 high-contrast-theme:hidden">
          {[...Array(bgRepeatY).keys()].map((keyY) =>
            [...Array(bgRepeatX).keys()].map((keyX) => {
              // console.log(keyX, keyY);
              const width = 150;
              const height = 40;
              return (
                <div
                  key={keyX.toString() + keyY.toString()}
                  className="pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 dark-theme:bg-primary-400/10"
                  style={{
                    top: 0,
                    left: 0,
                    width: `${width}px`,
                    height: `${height}px`,
                    transform: `translate(${
                      keyX * (height + 10) * 2 - (keyY & 1 ? width * 1 : 0)
                    }px, ${keyY * width}px) rotate(288.5deg)`,
                  }}
                  onMouseEnter={(e) => {
                    e.target.className =
                      "pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 sm:bg-primary-200/20 dark-theme:sm:bg-primary-500/10 transition-none dark-theme:bg-primary-400/10";
                  }}
                  onMouseLeave={(e) => {
                    var interval = setInterval(() => {
                      e.target.className =
                        "pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 transition-all duration-300 dark-theme:bg-primary-400/10";
                      clearInterval(interval);
                    }, 300);
                  }}
                ></div>
              );
            }),
          )}
        </div>
        <div className="pointer-events-none absolute -left-[.6875rem] bottom-0 right-0 top-0 z-10 high-contrast-theme:hidden">
          {[...Array(bgRepeatY).keys()].map((keyY) =>
            [...Array(bgRepeatX).keys()].map((keyX) => {
              // console.log(keyX, keyY);
              const width = 150;
              const height = 40;
              return (
                <div
                  key={keyX.toString() + keyY.toString()}
                  className="pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 dark-theme:bg-primary-400/10"
                  style={{
                    top: 0,
                    left: 0,
                    width: `${width}px`,
                    height: `${height}px`,
                    transform: `translate(${
                      keyX * (height + 10) * 2 - (keyY & 1 ? width * 1 : 0)
                    }px, ${keyY * width}px) rotate(288.5deg)`,
                  }}
                  onMouseEnter={(e) => {
                    e.target.className =
                      "pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 sm:bg-primary-200/20 dark-theme:sm:bg-primary-500/10 transition-none dark-theme:bg-primary-400/10";
                  }}
                  onMouseLeave={(e) => {
                    var interval = setInterval(() => {
                      e.target.className =
                        "pointer-events-auto absolute origin-center rounded-full bg-primary-200/5 transition-all duration-300 dark-theme:bg-primary-400/10";
                      clearInterval(interval);
                    }, 300);
                  }}
                ></div>
              );
            }),
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
