import React from "react";
import ContactForm from "@/components/contact/contact-form";

const ContactSection = ({ className = "" }) => {
  return (
    <section id="contact" className={className}>
      <h2 className="py-2">Let&apos;s Connect!</h2>
      <p className="pb-3">
        {
          "Whether you're a potential collaborator, a fellow developer, or someone interested in my work, I'm always open to new opportunities and conversations. Feel free to share your thoughts, ideas, or just a friendly hello. I'll make sure to get back to you as soon as possible."
        }
      </p>
      <ContactForm />
    </section>
  );
};

export default ContactSection;
