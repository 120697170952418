import Image from "next/image";
import React from "react";

import full_stack_dev from "@/public/img/full-stack-dev.svg";
import ai_machine_learning from "@/public/img/ai-machine-learning.svg";
import cloud_services from "@/public/img/cloud-services.svg";
import technical_consultation from "@/public/img/technical-consultation.svg";
import custom_software from "@/public/img/custom-software.svg";
import api_dev from "@/public/img/api-dev.svg";
import performance_optimization from "@/public/img/performance-optimization.svg";
import integration from "@/public/img/integration.svg";

const ServicesSection = ({ className = "" }) => {
  return (
    <section className={className}>
      <h2>Services</h2>
      <div className="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 md:grid-cols-3">
        <div className="card row-span-2">
          <div className="w-card-img-accent2">
            <Image className="card-image" src={full_stack_dev} alt="" />
          </div>
          <div className="card-content">
            <h3>Full Stack Development</h3>
            <ul>
              <li>End-to-End Application Development</li>
              <li>Custom Website Development</li>
              <li>Analytics Dashboard Development</li>
              <li>Serverless Architecture Implementation</li>
              <li>Responsive Web Design</li>
              <li>User Interface (UI) Design</li>
              <li>Database Design and Integration</li>
              <li>Single Page Application (SPA) Development</li>
              <li>Server-side API Development</li>
              <li>CMS Customization and Integration</li>
              <li>Payment Gateway Integration</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-secondary">
            <Image className="card-image" src={ai_machine_learning} alt="" />
          </div>
          <div className="card-content">
            <h3>AI and Machine Learning</h3>
            <ul>
              <li>AI-Powered Application Development</li>
              <li>Machine Learning Model Integration</li>
              <li>AI Chatbot Development</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-accent1">
            <Image className="card-image" src={cloud_services} alt="" />
          </div>
          <div className="card-content">
            <h3>Cloud Services</h3>
            <ul>
              <li>Cloud Infrastructure Setup (Azure)</li>
              <li>Cloud-Based Application Development</li>
              <li>Cloud Migration Services</li>
              <li>Serverless Functions Development</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-primary">
            <Image className="card-image" src={technical_consultation} alt="" />
          </div>
          <div className="card-content">
            <h3>Technical Consultation</h3>
            <ul>
              <li>Technology Stack Recommendation</li>
              <li>Project Evaluation and Planning</li>
              <li>Technical Support</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-accent2">
            <Image className="card-image" src={custom_software} alt="" />
          </div>
          <div className="card-content">
            <h3>Custom Software Development</h3>
            <ul>
              <li>Business Software Solutions</li>
              <li>Automation Tools Development</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-primary">
            <Image className="card-image" src={api_dev} alt="" />
          </div>
          <div className="card-content">
            <h3>API Development</h3>
            <ul>
              <li>RESTful API Development</li>
              <li>API Integration Services</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-secondary">
            <Image
              className="card-image"
              src={performance_optimization}
              alt=""
            />
          </div>
          <div className="card-content">
            <h3>Performance Optimization</h3>
            <ul>
              <li>Website/Application Performance Enhancement</li>
              <li>Code Optimization</li>
            </ul>
          </div>
        </div>

        <div className="card">
          <div className="w-card-img-accent1">
            <Image className="card-image" src={integration} alt="" />
          </div>
          <div className="card-content">
            <h3>Integration Services</h3>
            <ul>
              <li>Third-Party API Integration</li>
              <li>System Integration Solutions</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;

/**
 * Here are my services:
5. **Full-Stack Development:**
   - End-to-End Application Development
   - Payment Gateway Integration
   - CMS Customization and Integration
   - Custom Website Development
   - Responsive Web Design
   - User Interface (UI) Design
   - Single Page Application (SPA) Development
   - Analytics Dashboard Development
   - Server-side API Development
   - Database Design and Integration
   - Serverless Architecture Implementation
   
14. **AI and Machine Learning:**
    - AI Chatbot Development
    - AI-Powered Application Development
    - Machine Learning Model Integration

8. **Custom Software Development:**
   - Business Software Solutions
   - Automation Tools Development

9. **Cloud Services:**
   - Cloud Infrastructure Setup (Azure)
   - Cloud-Based Application Development

10. **API Development:**
    - RESTful API Development
    - API Integration Services

15. **Technical Consultation:**
    - Technology Stack Recommendation
    - Project Evaluation and Planning
    - Technical Support

18. **Performance Optimization:**
    - Website/Application Performance Enhancement
    - Code Optimization

20. **Integration Services:**
    - Third-Party API Integration
    - System Integration Solutions
 */
