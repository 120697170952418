import React from "react";

// interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
//   children?: JSX.Element | string;
//   className?: string;
//   variant?: "contained" | "outlined" | "text";
//   color?: "primary" | "secondary";
//   // [key: string]: React.ButtonHTMLAttributes<HTMLButtonElement>;
// }

const Button = ({ children, className, variant, color, ...restProps }) => {
  const variableClasses =
    color !== "secondary"
      ? variant === "outlined"
        ? "border-primary border border-solid hover:bg-primary-50/20 active:bg-primary-100/20"
        : variant === "text"
          ? "text-primary hover:bg-primary-50/20 active:bg-primary-100/20"
          : "bg-primary/90 hover:bg-primary-400/90 active:bg-primary/90"
      : variant === "outlined"
        ? "border-secondary border border-solid hover:bg-secondary-50/20 active:bg-secondary-100/20"
        : variant === "text"
          ? "text-secondary hover:bg-secondary-50/20 active:bg-secondary-100/20"
          : "bg-secondary/90 hover:bg-secondary-400/90 active:bg-secondary/90";

  return (
    <button
      className={`rounded-full p-2 px-4 text-white transition-all duration-100 active:shadow-[inset_0_2px_4px_0_rgba(0,0,0,0.2)] ${variableClasses} ${className}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
