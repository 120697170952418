import React from "react";
// import mdOptions from "@/utils/mdOptions";
// import Markdown from "markdown-to-jsx";
// import mdContent from "@/content/about-me-summary.md";
import Link from "next/link";

const AboutSection = ({ className = "" }) => {
  return (
    <section className={className}>
      {/* <Markdown options={mdOptions}>{mdContent}</Markdown> */}
      <p className="text-balance relative h-auto w-full text-center font-medium">
        <span className="absolute -left-4 -top-4 font-serif text-9xl text-auto-black/10">
          “
        </span>
        I embarked on my coding journey at nine, I blend technical prowess with
        managerial skills. Proficient in multiple programming languages, I excel
        in business modeling, product development, and AI. Let&apos;s connect
        and elevate your projects with my passion and expertise!
        <span className="absolute -bottom-4 -right-4 rotate-180 font-serif text-9xl text-auto-black/10">
          “
        </span>
        <span>
          <Link href="/about" className="link px-2">
            Read More...
          </Link>
        </span>
      </p>
    </section>
  );
};

export default AboutSection;
