import React from "react";
// import { Stack } from "@mui/material";
import Button from "@/components/w-button";
import { TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { TaskAlt as Done, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useForm, Controller, useFormState } from "react-hook-form";
import { motion } from "framer-motion";
import { useAnimationControls } from "framer-motion";
// import AnalyticsContext from "@/utils/AnalyticsProvider";

const validateEmail = require("email-validator");

const ContactForm = () => {
  // const analytics = React.useContext(AnalyticsContext);
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const form = useForm({
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const clearForm = () => {
    form.reset();
    setSent(false);
  };

  const handleSend = async (
    /** @type {{ name: any; email: any; subject: any; message: any; }} */ data,
  ) => {
    // e.preventDefault();
    // analytics.action("contact-form-submitted");
    setSending(true);
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
        }),
      });
      setSending(false);
      if (response.ok) {
        setSent(true);
        // analytics.success("contact-form-submitted");
      } else {
        // analytics.error("contact-form-submitted", response.statusText);
        enqueueSnackbar(
          "An error occured while sending your message. Please try again.",
          { variant: "error" },
        );
      }
    } catch (err) {
      console.log(err);
      // analytics.error("contact-form-submitted", err);
      enqueueSnackbar(
        "An error occured while sending your message. Please try again later.",
        { variant: "error" },
      );
      setSending(false);
    }
  };

  // Animation on Error

  const controls = useAnimationControls();

  // Declare a motion variant to shake the element along the x axis 3 times.
  const shake = {
    x: [0, -3, 3, -3, 3, -3, 3, 0],
    transition: { duration: 0.3 },
  };

  const { isSubmitting } = useFormState({ control });
  // Animate the element when the error changes.
  React.useEffect(() => {
    if (
      !isSubmitting &&
      (errors.name || errors.email || errors.subject || errors.message)
    ) {
      controls.start(shake);
    }
  }, [isSubmitting]);

  return (
    <div className="card card-content mb-5">
      {!sent ? (
        <form id="contact-form" noValidate onSubmit={handleSubmit(handleSend)}>
          <div className="flex flex-col space-y-2">
            <TextField
              id="name"
              label="Name"
              autoComplete="name"
              variant="outlined"
              fullWidth // helperText="We'll never share your name."
              // value={name}
              required
              // onChange={(event) => setName(event.target.value)}
              {...register("name", { required: "Name is required." })}
              error={!!errors.name}
              helperText={errors.name?.message}
              component={motion.div}
              animate={!!errors.name ? controls : {}}
            />
            <TextField
              id="email"
              label="Email"
              autoComplete="email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              required
              {...register("email", {
                required: "Email is required.",
                validate: (value) =>
                  validateEmail.validate(value) ||
                  "Please enter a valid email address.",
              })}
              helperText={
                errors.email?.message || "I will never share your email."
              }
              component={motion.div}
              animate={!!errors.email ? controls : {}}
              // value={email}
              // onChange={(event) => setEmail(event.target.value)}
            />
            <TextField
              id="subject"
              label="Subject"
              autoComplete="subject"
              variant="outlined"
              fullWidth
              required
              // value={subject}
              // onChange={(event) => setSubject(event.target.value)}
              {...register("subject", { required: "Subject is required." })}
              error={!!errors.subject}
              helperText={errors.subject?.message}
              component={motion.div}
              animate={!!errors.subject ? controls : {}}
            />
            <TextField
              id="message"
              label="Message"
              autoComplete="message"
              variant="outlined"
              fullWidth
              multiline
              required
              rows={4}
              // value={message}
              // onChange={(event) => setMessage(event.target.value)}
              {...register("message", { required: "Message is required." })}
              error={!!errors.message}
              helperText={errors.message?.message}
              component={motion.div}
              animate={!!errors.message ? controls : {}}
            />
            <div className="h-10 w-24">
              <Button
                className="h-full w-full"
                variant="contained"
                color="secondary"
                // size="large"
                type="submit"
                disabled={sending}
              >
                {sending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send"
                )}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <div className="relative flex h-full w-full flex-col items-center justify-center p-4">
          <Done sx={{ fontSize: 64 }} className="fill-success" />
          <IconButton
            onClick={clearForm}
            size="large"
            color="inherit"
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <Close />
          </IconButton>
          <h2 className="py-2 text-success">Hang on tight!</h2>
          <h3 className="pb-3 text-center">
            I will get back to you as soon as possible.
          </h3>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
