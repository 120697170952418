import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonials from "@/content/testimonials.json";
import Image from "next/image";
import { useMediaQuery } from "@mui/material";
import Rating from "@mui/material/Rating";

const Testimonials = () => {
  const isTooSmall = useMediaQuery("(max-width: 300px)");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isDesktop = useMediaQuery("(min-width: 900px)");

  return (
    <section className="py-12">
      <div className="container flex items-center justify-center">
        <h2>Testimonials</h2>
        {/* {isTooSmall && <p>tooSmall</p>}
        {isMobile && <p>mobile</p>}
        {isDesktop && <p>desktop</p>} */}
      </div>
      <div className="relative mx-auto max-w-[1920px] pt-8">
        <Slider
          className="mt-4"
          autoplay
          autoplaySpeed={5000}
          dots={false}
          arrows={false}
          draggable
          cssEase="cubic-bezier(.5,0,.5,1)"
          infinite
          speed={1000}
          pauseOnDotsHover
          pauseOnHover
          slidesToShow={4}
          slidesToScroll={1}
          centerMode
          centerPadding="48px"
          variableWidth
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                centerMode: true,
                centerPadding: "48px",
                slidesToShow: 3,
                slidesToScroll: 1,
                variableWidth: true,
              },
            },
            {
              breakpoint: 900,
              settings: {
                centerMode: true,
                centerPadding: "24px",
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                centerMode: true,
                centerPadding: "28px",
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
              },
            },
            {
              breakpoint: 300,
              settings: {
                centerMode: true,
                centerPadding: "20px",
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
              },
            },
          ]}
        >
          {testimonials.map((testimonial) => (
            <div key={testimonial.name} className="px-2 py-4">
              <div
                className="card p-4 sm:min-w-[15rem] sm:max-w-[30rem]"
                style={{
                  width: `${
                    isMobile
                      ? "auto"
                      : isDesktop
                        ? testimonial.text.length / 15 + "rem"
                        : testimonial.text.length / 25 + "rem"
                  }`,
                  //   minWidth: "20rem",
                  //   maxWidth: "50rem",
                }}
              >
                <h3 className="p-0 text-xl">{testimonial.name}</h3>
                <div className="flex">
                  <Image
                    className="mr-2 h-6 object-fill object-center"
                    width="20"
                    height="20"
                    alt={`${testimonial.country} Flag`}
                    src={`./img/flags/${testimonial.cc}.svg`}
                  />
                  <p className="h-5 p-0 pt-0.5 text-sm">
                    {testimonial.country}
                  </p>
                </div>
                <Rating value={5} readOnly className="py-4" />
                <blockquote
                  //   className={`pt-2 text-left`}
                  className={`text-pretty text-left ${
                    testimonial.text.length > 350 ? " text-sm sm:text-base" : ""
                  }`}
                >
                  {testimonial.text}
                </blockquote>
              </div>
            </div>
          ))}
        </Slider>
        {!isTooSmall && (
          <>
            <div className="pointer-events-none absolute bottom-0 left-0 top-0 w-4 bg-gradient-to-r from-html-background sm:w-6 md:w-12 xl:w-[10%] 2xl:w-[20%]" />
            <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-4 bg-gradient-to-l from-html-background sm:w-6 md:w-12 xl:w-[10%] 2xl:w-[20%]" />
          </>
        )}
      </div>
    </section>
  );
};

export default Testimonials;
