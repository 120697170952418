import React from "react";
import SkillsTop from "./skills-top";
import SkillsBottom from "./skills-bottom";
// For GPT: In each technology below, I need a description that explains the technology from a business perspective in one sentence. The website is targeted towards business managers, so there is no need for technical details.

const SkillsSection = ({ className = "" }) => {
  return (
    <section className={className}>
      <div className="break-inside-avoid-column">
        <h2>Skills and Technologies</h2>
        <p className="max-w-prose text-balance">
          My journey in IT has equipped me with a versatile skill set and a
          passion for problem-solving. I thrive in the dynamic world of
          technology, continuously expanding my knowledge and expertise to
          create innovative solutions. Here are some of the technologies
          I&apos;ve worked with. You can <span className="md:hidden">tap</span>
          <span className="hidden md:inline-block">hover over</span> the logos
          to learn more about each technology.
        </p>
      </div>
      <div className="card mt-4 h-64 py-4">
        <SkillsTop />
        <SkillsBottom />
      </div>
    </section>
  );
};

export default SkillsSection;
